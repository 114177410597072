window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};

Object.defineProperty(window, 'midiaSize', {
	get() {
		var w = window.innerWidth;
		var size = 'xs';

		if(w < 500) {
			size = 'xs';
		}

		if(w >= 500) {
			size = 'sm';
		}

		if(w >= 768) {
			size = 'md';
		}

		if(w >= 992) {
			size = 'lg';
		}

		if(w >= 1200) {
			size = 'xl';
		}

		return size;
	}
});

if(!Array.prototype.forEach) {
	Array.prototype.forEach = function(callback, argumentos) {
		if(this === null) {
			throw new TypeError('this is null or not defined')
		}

		if(typeof callback !== 'function') {
			throw new TypeError(callback+' is not a function')
		}

		for(var i=0;i<this.length;i++) {
			var fn = callback.bind(this[i]);

			fn(this[i], argumentos);
		}
	}
}
